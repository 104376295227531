import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDimensions } from '../../../Logic/Dimensions';
import ContactForm from '../../ContactForm';
import Assets from '../../Layouts/Assets';
import Style from "./Contact.module.scss";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const windowDimensions = useDimensions();
  const { t } = useTranslation(["contact"]);

  return (
    <>
      <div className={`${Style.contact_page}`}>
        <section className={Style.contact_banner}>
          <figure className={Style.contact_banner_image}>
            <img src={Assets.contact_banner} alt="" />
          </figure>
          {windowDimensions.width < 1279 && (
            <div className='container'>
              <div className={Style.contact_contents_head}>
                <h1 className={Style.contact_title}>{t("contact_title")}</h1>
              </div>
            </div>
          )}
        </section>
        <section className={Style.contact_contents}>
          <div className='container'>
            <div className={Style.contact_wrap}>
              <div className={Style.contact_contents_head}>
                {windowDimensions.width >= 1279 && (
                  <h1 className={Style.contact_title}>{t("contact_title")}</h1>
                )}
                <div className={Style.mail_wrapper}>
                <span className={Style.contact_email_label}>{t("email_heading")}</span>
                <a href="mailto:info@familyphotos.com" target="_blank" className={Style.contact_email_address}>info@familyphotos.com</a>
                </div>
                
                <div>
                <span className={Style.contact_email_label}>{t("operational_heading")}</span>
                <p className={Style.contact_email_address_content}>{t("operational_address")}</p>
                </div>

               
              </div>
              <div className={Style.contact_form_wrap}>
                <figure className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}>
                  <img src={Assets.browse_circle_green} alt="circle_yellow" />
                </figure>
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Contact;