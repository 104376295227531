import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="inner-page">
        <div className="container">
          <h1 className="policies-title">Privacy Policy</h1>
          {/* <p className="policies-date">Last updated on: 28 November 2024</p> */}
          <div className="admin-content-area">
            <h2>A. Preface</h2>
            <ol className="lower-alpha">
              <li>
                <a href="http://familyphotosalbum.com/" className="a">
                  FamilyPhotosAlbum.com
                </a>
                , conveniently called as FPA is a web portal for the general
                public. Members who have registered in FPA can upload digital
                photographs and use their page as a personal digital album.
                Members can organise the photos by rearranging the positions of
                the photos in the gallery or re-group into different sub albums.
              </li>
              <li>
                Personal album created by members will be private to the user by
                default. Only this user can view their personal album, which is
                protected by the username and password that they create.
              </li>
              <li>
                Others can view the albums in part or full only if the user
                shares the photo/photos through shareable link to others, a
                feature available in FPA.
              </li>
              <li>
                The information put in the Family Tree by the user are visible
                only to the user and those who share the family tree links with.
                These details cannot be seen by the public.
              </li>
              <li>
                For increasing the performance of the user page, FPA may resize
                or optimise the photos, at the same time retaining the quality
                levels for comfortable viewing.
              </li>
              <li>
                FPA is committed to safeguarding your privacy. This policy
                statement explains how we collect, process, and disclose
                information about you.
              </li>
              <li>
                Even though the web portal is multilingual, the Information and
                Guidelines, Privacy Policy, and e-mail communications from FPA
                will be in the English language only.
              </li>
            </ol>

            <p>This policy addresses the following aspects:</p>

            <h2>
              B. The information we collect and the sources of such information
            </h2>
            <ol>
              <li>
                During the registration process, we collect the name, postal
                address, e-mail ID and phone number of the member trying to
                create the account. These are considered to be essential for
                contacting our members/subscribers as part of the services being
                provided. Members may also provide their profile photo which we
                collect and display on their pages.
              </li>
              <li>
                We make use of Google Analytics to identify which pages on our
                website are visited most. Google Analytics also tells us from
                which country you are accessing the FPA web portal. 
              </li>
              <li>
                We also use a third-party application to identify the country
                flag based on the home country you select, during the
                registration process. The home country flag may be displayed on
                the user’s profile page.
              </li>
            </ol>

            <h2>C. Storing your personal information</h2>
            <ol>
              <li>
                The data collected during the registration process are stored in
                the database of the FPA portal. This information is not
                available to the public.
              </li>
              <li>
                The above data is stored within the server in an encrypted form
                which is accessible only to the Data Processor/Administrator.
              </li>
              <li>
                We do not disclose or pass on your personal information to any
                outsider.
              </li>
              <li>
                Data is stored in the Amazon/Google Cloud Servers where the
                security features are class leading. 
              </li>
              <li>
                The Data Processor’s premise has a system of periodical data
                safety review and vulnerability assessments to check whether
                there is any potential attack or gaps.
              </li>
            </ol>

            <h2>D. Use of cookies</h2>
            <ol>
              <li>
                FPA is not using any Cookies. Still, the users can select their
                browser setting to decline cookies if they prefer to. Such a
                selection will not affect the performance of the FPA portal.
              </li>
            </ol>

            <h2>E. Links to other websites</h2>
            <p>
              The FPA pages may contain links to other websites of the
              advertisers. Once the user clicks on these links to leave the FPA
              site, the policies of those websites will apply. 
            </p>

            <h2>
              F. Protection of your rights regarding your private information
            </h2>
            <ol>
              <li>
                We are bound to tell you what personal information we have in
                store about you. 
              </li>
              <li>
                You have the right to:
                <ol className="lower-alpha">
                  <li>Ask to view the personal data we store about you. </li>
                  <li>
                    Request the correction of your personal data if you find it
                    incorrect, out of date or incomplete.
                  </li>
                  <li>
                    Change your mind about your preferences or withdraw consent
                    for us to store your personal data.
                  </li>
                </ol>
              </li>
              <li>
                If you make a request, the Data Controller will take action to
                get all the personal information about you deleted within a
                period not later than 72 hours of the receipt of your request.
                The Data Processor has created a “Forget Me” button in the admin
                panel for this quick action. 
              </li>
            </ol>

            <h2>G. Photographs posted by users</h2>
            <p>
              FPA will not take any responsibility for the content of the
              photographs posted on our platform. The users can be Individuals
              or Photo Studios or Wedding Album Photographers. If any of the
              users report abuse of the FPA platform by way of posting and
              sharing of explicit content, FPA will inform the user. If the
              content is not removed within 24 hours, FPA Administrator will
              remove the content from the back end. If the abuse is repeated,
              FPA will be compelled to cancel the membership of the user.
            </p>

            <h2>H. How long do we keep your information</h2>
            <p>
              We store your content as long as you continue to be FPA’s
              subscriber. We continue to keep your content for a period of 3
              months (three months) even after the expiry of your subscription.
              If you instruct us clearly to remove/delete your contents, we will
              remove your files and information from the system within 72 hours
              of the receipt of request.
            </p>

            <h2>I. Our right to delete your information</h2>
            <p>
              If we suspect fraudulent registrations or if we do not get a
              response to our repeated e-mails, or if the account is not renewed
              by paying the subscription fee for more than three months, the
              account along with its contents may get deleted.
            </p>

            <h2>J. Data Controller and Data Processor</h2>
            <p>
              <b>Data Controller</b>: K G Girish Babu, Managing Director,
              Hamphotos Private Limited (E mail:{" "}
              <a href="mailto:girishbabu@mac.com" className="a">
                girishbabu@mac.com
              </a>
              )
            </p>
            <p>
              <b>Data Processor</b>: Webandcrafts Technology Solutions Pvt. Ltd
              (
              <a
                href="https://webandcrafts.com/?utm_source=familyphotosalbum.com&utm_medium=referral&utm_campaign=familyphotosalbum.com_referral_wac_client"
                className="a"
              >
                webandcrafts.com
              </a>
              )
            </p>

            <h2>K. Changes to the privacy policy</h2>
            <p>We keep our privacy notices under periodical review.</p>
            <i>FPA/PP/V 1.07 as updated on 28th November 2024</i>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
